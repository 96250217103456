import React, { useEffect, useState } from 'react';
import { IoMdAddCircleOutline } from 'react-icons/io';
import {
  CategoryModal,
  CategoryPreview,
  MobileCategoryPreview,
} from './modals';
import { AiOutlineEdit } from 'react-icons/ai';
import { HiOutlineEye } from 'react-icons/hi2';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import customFetch, { checkForUnauthorizedResponse } from '../utils/axios';
import { toast } from 'react-toastify';
import arrowImg from '../assets/arrowPreview.svg';

import { useDispatch } from 'react-redux';
import ColorAndTitleInput from './ColorAndTitleInput';

export default function Categories({ profileData }) {
  const [open, setOpen] = useState(false);
  const [myCategories, setMyCategories] = useState([]);
  const [openCategoryPreview, setOpenCategoryPreview] = useState(false);
  const [openMobileCategoryPreview, setOpenMobileCategoryPreview] =
    useState(false);
  const [isLoadingMyCategories, setIsLoadingMyCategories] = useState(false);
  const handleToggle = () => setOpen((prev) => !prev);
  const handleToggleCategoryPreview = () =>
    setOpenCategoryPreview((prev) => !prev);
  const dispatch = useDispatch();
  // handle toggle mobile popup preview
  const handleToggleMobileCategoryPreview = () =>
    setOpenMobileCategoryPreview((prev) => !prev);
  const queryClient = useQueryClient();
  // color picker
  const [colors, setColors] = useState({
    primary_color: '',
    secondary_color: '',
  });
  const [titles, setTitles] = useState({
    arabicTitle: profileData?.store?.popup_title.ar || 'أضف المزيد من المنتجات',
    englishTitle: profileData?.store?.popup_title.en || 'Add more products',
  });
  const handleChange = (e) => {
    setColors({
      ...colors,
      [e.target.name]: e.target.value,
    });
  };
  const handleTitleChange = (e) => {
    setTitles({
      ...titles,
      [e.target.name]: e.target.value,
    });
  };

  // const { data: myProducts, isLoading: isLoadingMyProducts } = useQuery({
  //   queryKey: ['myProducts'],
  //   queryFn: async () => {
  //     const { data } = await customFetch('/products');
  //     return data.data;
  //   },
  //   onError: (error) => {
  //     checkForUnauthorizedResponse(error, dispatch);
  //   },
  // });
  // add Products To sala
  const { mutate: addCategories, isLoading: isLoadingAddCategories } =
    useMutation({
      mutationFn: async (categories) => {
        const { data } = await customFetch.post('/categories', {
          categories: categories,
        });
        return data;
      },
      onSuccess: () => {
        updateColor(colors);
        updateTitle(titles);
      },
      onError: (error) => {
        checkForUnauthorizedResponse(error, dispatch);
      },
    });
  const fetchMyCategories = async () => {
    setIsLoadingMyCategories(true);
    try {
      const { data } = await customFetch('/categories');
      setMyCategories(data.data);
      setIsLoadingMyCategories(false);
    } catch (error) {
      checkForUnauthorizedResponse(error, dispatch);
      setIsLoadingMyCategories(false);
    }
  };
  useEffect(() => {
    fetchMyCategories();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (myCategories.length === 0) {
      toast.error('لا يوجد تصنيفات لإضافتها');
      return;
    }
    addCategories(myCategories);
  };
  // handle color picker
  const { mutate: updateColor, isLoading: isLoadingUpdateColor } = useMutation({
    mutationFn: async (dataToSend) => {
      const { data } = await customFetch.post('/profile/update-store-colors', {
        popup_title: dataToSend.arabicTitle,
      });
      return data.data;
    },
    onSuccess: (data) => {},
    onError: (error) => {
      checkForUnauthorizedResponse(error, dispatch);
    },
  });
  // handle title
  const { mutate: updateTitle, isLoading: isLoadingUpdateTitle } = useMutation({
    mutationFn: async (dataToSend) => {
      const { data } = await customFetch.post(
        '/profile/update-store-popup-title',
        {
          popup_title_ar:
            dataToSend.arabicTitle ||
            profileData?.store?.popup_title.ar ||
            'من فضلك أدخل العنوان ',
          popup_title_en:
            dataToSend.englishTitle ||
            profileData?.store?.popup_title.en ||
            'Please enter the title',
        }
      );
      return data.data;
    },
    onSuccess: (data) => {
      toast.success('تم تحديث البيانات بنجاح');
    },
    onError: (error) => {
      checkForUnauthorizedResponse(error, dispatch);
    },
  });
  return (
    <div className='mt-3 sm:mt-5 bg-[#F7F7F8] rounded-md py-5 sm:py-10 px-3 '>
      {isLoadingMyCategories ? (
        <span className='loading loading-dots text-primary loading-lg mx-auto block'></span>
      ) : myCategories?.length === 0 ? (
        <div className='grid place-items-center'>
          <div
            className='flex flex-col gap-1 justify-center items-center bg-base-100 border-[1px] border-primary w-52 h-44 rounded-lg text-primary cursor-pointer hover:bg-primary transition duration-300 hover:text-base-100'
            onClick={handleToggle}
          >
            <IoMdAddCircleOutline className='text-2xl' />
            <span className='font-bold'>اضافة تصنيف</span>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          {/* top */}
          <div className='flex justify-between items-center flex-wrap sm:flex-nowrap gap-3'>
            <h2 className='text-primary text-lg font-semibold'>
              التصنيفات المضافة في واجهة السلة
            </h2>
            <div className='flex gap-2'>
              {/* <div className='dropdown dropdown-bottom'>
                <button
                  tabIndex={0}
                  role='button'
                  type='button'
                  className='btn btn-sm text-primary bg-base-100'
                >
                  <HiOutlineEye className='text-lg' />
                  معاينة
                  <img src={arrowImg} alt='arrowImg' />
                </button>
                <ul
                  tabIndex={0}
                  className='dropdown-content z-[1] menu p-2 shadow bg-base-100 w-full'
                >
                  {profileData?.store?.subscription_plan?.web_access_rule ===
                    true && (
                    <li>
                      <button
                        onClick={handleToggleCategoryPreview}
                        className='px-0'
                        role='button'
                        type='button'
                      >
                        وضع الويب
                      </button>
                    </li>
                  )}
                  {profileData?.store?.subscription_plan?.mobile_access_rule ===
                    true && (
                    <li>
                      <button
                        className='px-0'
                        role='button'
                        type='button'
                        onClick={handleToggleMobileCategoryPreview}
                      >
                        وضع التطبيق
                      </button>
                    </li>
                  )}
                </ul>
              </div>  */}
              <button
                type='button'
                className='btn btn-sm text-primary bg-base-100'
                onClick={handleToggle}
              >
                تعديل
                <AiOutlineEdit className='text-lg' />
              </button>
            </div>
          </div>
          {/* items */}
          {/* max-h-[400px] overflow-y-auto  */}
          <div className='flex flex-col gap-3 mt-3 sm:mt-5'>
            {isLoadingMyCategories ? (
              <span className='loading loading-dots text-primary loading-lg mx-auto block'></span>
            ) : (
              myCategories?.map((category) => (
                <div
                  className={`flex justify-between py-2 sm:py-4 cursor-pointer rounded-lg px-3 border-[1px] border-gray-200 bg-base-100`}
                  key={category.id}
                >
                  <div className='flex gap-8'>
                    <div className='w-[120px] h-[120px]'>
                      <img
                        src={category?.image}
                        alt='category image'
                        className='w-full h-full object-contain rounded-2xl'
                      />
                    </div>
                    <div className='flex flex-col'>
                      <h4 className='text-lg font-semibold'>
                        {category?.name}
                      </h4>

                      {category?.sale_price?.amount && (
                        <span className='text-sm text-gray-400 font-semibold line-through'>
                          {category?.price?.amount}
                          {category?.price?.currency}
                        </span>
                      )}
                      <span className='text-sm text-error font-semibold'>
                        {category?.sale_price?.amount
                          ? category?.sale_price?.amount
                          : category?.price?.amount}
                        {category?.price?.currency}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            )}
            {/* Color Picker */}
            <ColorAndTitleInput
              primaryColor={profileData?.store?.primary_color}
              secondaryColor={profileData?.store?.secondary_color}
              colors={colors}
              oldTitle={profileData?.store?.popup_title}
              titles={titles}
              handleTitleChange={handleTitleChange}
              handleChange={handleChange}
            />
            <button
              className='btn btn-primary self-end w-[205px]  rounded-[28px]'
              type='submit'
              disabled={isLoadingAddCategories}
            >
              {isLoadingAddCategories ||
              isLoadingUpdateColor ||
              isLoadingUpdateTitle ? (
                <span className='loading loading-spinner'></span>
              ) : (
                'حفظ'
              )}
            </button>
          </div>
        </form>
      )}
      {open && (
        <CategoryModal
          open={open}
          handleToggle={handleToggle}
          myCategories={myCategories}
          setMyCategories={setMyCategories}
        />
      )}
      {openCategoryPreview && (
        <CategoryPreview
          openCategoryPreview={openCategoryPreview}
          handleToggleCategoryPreview={handleToggleCategoryPreview}
          myCategories={myCategories}
        />
      )}
      {openMobileCategoryPreview && (
        <MobileCategoryPreview
          openMobileCategoryPreview={openMobileCategoryPreview}
          handleToggleMobileCategoryPreview={handleToggleMobileCategoryPreview}
          myCategories={myCategories}
        />
      )}
    </div>
  );
}
