import React from 'react';
import infoImg from '../assets/Info.svg';
import toggleImgOnn from '../assets/icons/toggleOn.svg';

import toggleImgOFF from '../assets/icons/toggleOff.svg';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import customFetch, { checkForUnauthorizedResponse } from '../utils/axios';
import { useDispatch } from 'react-redux';
import SmallLoading from './SmallLoading';
export default function WebSallaControl({ isLoadingProfile, data }) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { mutate: handleScriptStatus, isLoading: isLoadingScriptStatus } =
    useMutation({
      mutationFn: async (status) => {
        const { data } = await customFetch.post('/update-zid-script', {
          status,
        });
        return data;
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['profile'] });
      },
      onError: (error) => {
        checkForUnauthorizedResponse(error, dispatch);
      },
    });
  return (
    <div>
      <div className='flex items-center gap-2'>
        <h3 className='text-primary text-lg sm:text-xl font-semibold'>
          قسم واجهة السلة للويب
        </h3>
        {isLoadingScriptStatus || isLoadingProfile ? (
          <SmallLoading />
        ) : data?.store?.zid_script_status ? (
          <button
            onClick={() => {
              if (data?.store?.subscription_plan?.web_access_rule !== true)
                return;
              handleScriptStatus(false);
            }}
            disabled={
              data?.store?.subscription_plan?.web_access_rule !== true
                ? true
                : false
            }
            className={`${
              data?.store?.subscription_plan?.web_access_rule !== true &&
              'cursor-not-allowed'
            }`}
          >
            <img src={toggleImgOnn} alt='toggole on' className='toggleScript' />
          </button>
        ) : (
          <button
            onClick={() => {
              if (data?.store?.subscription_plan?.web_access_rule !== true)
                return;
              handleScriptStatus(true);
            }}
            disabled={
              data?.store?.subscription_plan?.web_access_rule !== true
                ? true
                : false
            }
            className={`${
              data?.store?.subscription_plan?.web_access_rule !== true &&
              'cursor-not-allowed'
            }`}
          >
            <img
              src={toggleImgOFF}
              alt='toggole off'
              className='toggleScript'
            />
          </button>
        )}
        {/* <input
          type='checkbox'
          className='toggle toggle-primary bg-[#707070] h-5'
          // checked={a === 'products'}
        /> */}
      </div>
      <div className='flex gap-2 items-center mt-3'>
        <img src={infoImg} alt='info' />
        <span className='text-gray-500'>
          يمكنك تفعيل بوب اب واحد في المتجر ( المنتجات أو تصنيفات مع منتجاتها )
        </span>
      </div>
    </div>
  );
}
